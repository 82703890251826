import * as React from "react"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"

import Container from "@components/elements/container"

import styles from "./heroAbout.module.scss"

interface HeroAboutProps {
  title: string
  text: string
}

const HeroAbout = ({ title, text }: HeroAboutProps) => {
  return (
    <section className={styles.heroAbout}>
      <Container className={styles.container}>
        <h1 className={styles.title}>{parse(title, { trim: true })}</h1>

        {text && <div className={styles.text}>{text}</div>}

        <div className={styles.pictureCont}>
          <StaticImage
            className={styles.picture}
            src="../images/city.png"
            alt="Quotation marks"
            objectFit="contain"
            placeholder="tracedSVG"
          />
        </div>
      </Container>
    </section>
  )
}

export default HeroAbout

import * as React from "react"
import { useState, useCallback } from "react"
import cx from "classnames"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import Link from "@components/elements/link"
import Lightbox from "@components/elements/lightbox"
import { ConditionalWrapper } from "@lib/utils"

import PlusIcon from "@images/plus-icon.inline.svg"

import styles from "./persons.module.scss"

interface Person {
  name: string
  position: string
  text?: string
  linkedinUrl?: string
  imageUrl: string
}

interface PersonsProps {
  title?: string
  text?: string
  columnsNr: number
  isDark?: boolean
  items: Array<Person>
}

const Persons = ({
  title,
  text,
  columnsNr = 3,
  items = [],
  isDark,
}: PersonsProps) => {
  const [activeIndex, setActiveIndex] = useState<Number | null>(null)
  const show = useCallback(
    (index: React.SetStateAction<Number | null>) => setActiveIndex(index),
    []
  )
  const hide = useCallback(() => setActiveIndex(null), [])

  if (!items.length) {
    return null
  }

  return (
    <>
      <section
        className={cx(styles.persons, {
          [styles.hasDarkBg]: isDark,
        })}
      >
        <Container className={styles.container}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {text && <div className={styles.text}>{text}</div>}

          <ul className={cx(styles.personList, styles[`col${columnsNr}`])}>
            {items.map((item, index) => {
              return (
                <li className={styles.person} key={item.name}>
                  <ConditionalWrapper
                    condition={item.text}
                    wrapper={wrapChildren => {
                      return (
                        <button
                          className={styles.personButtonOpen}
                          type="button"
                          onClick={() => show(index)}
                        >
                          {wrapChildren}
                          <PlusIcon className={styles.personButtonOpenIcon} />
                        </button>
                      )
                    }}
                  >
                    <div className={styles.personImage}>
                      <img
                        src={item.imageUrl}
                        srcSet={`${item.imageUrl} 2x`}
                        loading="lazy"
                        alt={`Headshot of ${item.name}`}
                      />
                    </div>
                  </ConditionalWrapper>
                  {item.name && (
                    <h3 className={styles.personName}>{item.name}</h3>
                  )}
                  {item.position && (
                    <div className={styles.personPosition}>{item.position}</div>
                  )}
                </li>
              )
            })}
          </ul>
        </Container>
      </section>

      {items[activeIndex] && (
        <Lightbox
          onClose={hide}
          className={styles.lightbox}
          containerClassName={styles.lightboxContainer}
        >
          <div className={styles.lightboxPerson}>
            {items[activeIndex].name && (
              <div className={styles.lightboxName}>
                {items[activeIndex].name}
              </div>
            )}
            {items[activeIndex].position && (
              <div className={styles.lightboxPosition}>
                {items[activeIndex].position}
              </div>
            )}
            {items[activeIndex].text && (
              <Wysiwyg
                className={styles.lightboxText}
                contentHtml={items[activeIndex].text}
              />
            )}
            {items[activeIndex].linkedinUrl && (
              <Link
                className={styles.lightboxLink}
                url={items[activeIndex].linkedinUrl}
              >
                LinkedIn
              </Link>
            )}
          </div>
        </Lightbox>
      )}
    </>
  )
}

export default Persons

import * as React from "react"

import styles from "./noiseBackground.module.scss"

interface NoiseBackgroundProps {
  children?: React.ReactNode
}

const NoiseBackground = ({ children }: NoiseBackgroundProps) => {
  if (!children) {
    return null
  }

  return <div className={styles.noiseBackground}>{children}</div>
}

export default NoiseBackground

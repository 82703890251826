import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import NoiseBackground from "@components/elements/noiseBackground"
import HeroAbout from "@components/heroAbout"
import TextBox from "@components/textBox"
import Persons from "@components/persons"
import Quote from "@components/quote"
import CtaSection from "@components/ctaSection"

const About = ({ data }: any) => {
  const { heroData, contentData, teamData, peopleData, quoteData } =
    data.pageData

  return (
    <Layout>
      <NoiseBackground>
        <HeroAbout {...heroData} />
        <TextBox {...contentData} />
      </NoiseBackground>
      <Persons {...teamData} isDark />
      {peopleData.map(
        peopleList => peopleList.isVisible && <Persons {...peopleList} />
      )}
      <Quote {...quoteData} />
      <CtaSection {...data.ctaSectionData} />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    pageData: aboutJson {
      heroData: hero {
        title
        text
      }
      contentData: content {
        intro
        text
        imageUrl
      }
      teamData: team {
        title
        text
        columnsNr
        items {
          name
          position
          text
          linkedinUrl
          imageUrl
        }
      }
      peopleData: people {
        title
        text
        columnsNr
        isVisible
        items {
          name
          position
          text
          linkedinUrl
          imageUrl
        }
      }
      quoteData: quote {
        text
        author
        authorDesc
      }
    }
    ctaSectionData: commonJson {
      title
      text
      cta {
        text
        url
      }
    }
  }
`

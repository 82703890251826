import * as React from "react"
import { useState } from "react"
import { marked } from "marked"
import cx from "classnames"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta from "@components/elements/cta"

import styles from "./textBox.module.scss"

interface textBoxProps {
  intro?: string
  text?: string
  imageUrl: string
}

const textBox = ({ intro, text, imageUrl }: textBoxProps) => {
  const textHtml = text && marked.parse(text)
  const [expanded, setExpanded] = useState<Boolean>(false)

  return (
    <section className={styles.textBox}>
      <Container className={styles.container}>
        <div className={styles.box}>
          <div
            className={cx(styles.boxContent, {
              [styles.isExpanded]: expanded,
            })}
          >
            {intro && <h2 className={styles.intro}>{intro}</h2>}
            <Wysiwyg className={styles.text} contentHtml={textHtml} />
            {imageUrl && (
              <img
                className={styles.sign}
                src={imageUrl}
                srcSet={`${imageUrl} 2x`}
                alt=""
                loading="lazy"
              />
            )}
          </div>
          {!expanded && (
            <Cta
              text="Read further"
              onClick={() => setExpanded(true)}
              variant="secondary"
              className={styles.buttonExpand}
            />
          )}
        </div>
      </Container>
    </section>
  )
}

export default textBox
